<template>
  <van-popup
    closeable
    close-icon-position="top-left"
    v-model="code.codeShow"
    @close="closeIcon"
    round
    position="bottom"
    :style="{ height: '96%' }"
  >
    <van-loading
      v-if="code.codeLoading"
      class="codeLoading"
      size="28px"
      color="#0094ff"
      text-color="#0094ff"
      vertical
      >加载中...</van-loading
    >
    <div class="codeWrap" v-else>
      <h1>长按保存图片</h1>
      <img :src="code.codeImg" class="codeImg" />
    </div>
  </van-popup>
</template>

<script>
import { Popup, Loading } from "vant";
import { post } from "@get/http";
export default {
  props: {
    boo: {
      default: false,
      type: Boolean,
    },
    g_id: {},
  },
  components: {
    vanPopup: Popup,
    vanLoading: Loading,
  },
  created() {
    this.code.codeShow = this.boo;
  },
  data() {
    return {
      code: {
        codeShow: false,
        codeImg: "",
        codeLoading: true,
      },
    };
  },
  watch: {
    boo() {
      if (this.boo == true) {
        this.goodsPoster();
      }
    },
    g_id() {
      this.code.codeLoading = true;
    },
  },
  methods: {
    closeIcon() {
      this.code.codeShow = false;
      this.$emit("update:boo", false);
    },
    async goodsPoster() {
      this.code.codeShow = true;
      if (this.code.codeLoading) {
        let data = await post("/Member/goods_poster", {
          data: {
            goods_id: this.g_id,
          },
        });

        this.code.codeImg = data.goods_poster;
        this.code.codeLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.codeWrap {
  height: 100%;
  position: relative;
  h1 {
    font-size: rem(34);
    text-align: center;
    margin-top: rem(30);
    font-weight: bold;
  }
  .codeImg {
    width: 100%;
    padding: rem(34);
    box-sizing: border-box;
  }
}
.codeLoading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: rem(100);
}
</style>
