<template>
  <div>
    <div class="goods_header">
      <div class="flex-container">
        <div class="g-title">
          <p class="title">
            {{ detail && detail.goods_name }}
          </p>
        </div>
      </div>
      <div class="g-subtitle">
        {{ detail && detail.goods_advword }}
      </div>

      <div class="g-h-icon flex-container">
        <!-- <span class="g-h-i-yj" @click="getYjCount">佣金</span> -->

        <span class="g-h-i-code" @click="codeImgBoo = true">二维码</span>
        <span class="g-h-i-share" @click="shareBoo = true">分享赚佣金</span>
      </div>

      <oCodeImgs :boo.sync="codeImgBoo" :g_id="g_id"></oCodeImgs>
      <oShareImg
        :boo.sync="shareBoo"
        :goodsName="detail && detail.goods_name"
        :g_id="g_id"
      ></oShareImg>
    </div>
  </div>
</template>

<script>
import oCodeImgs from "./codeImage.vue";
import oShareImg from "./share.vue";
import { Toast } from "vant";
import { post } from "@get/http";
import { mapState } from "vuex";
export default {
  data() {
    return {
      codeImgBoo: false,
      shareBoo: false,
    };
  },

  computed: mapState({
    user: (state) => state.user.info,
  }),

  props: {
    g_id: {
      default: "",
    },
    detail: {
      type: Object,
    },
  },

  components: {
    oCodeImgs,
    oShareImg,
  },
  methods: {
    getSubText(item) {
      /***
       * @method  getSubText 返回提示文本
       * @param item  当前sku参数
       * @return {string} 如返回提示文本
       *
       */
      let index = item.indexOf("[");
      let lastIndex = item.lastIndexOf("]");
      if (index != -1 && lastIndex != -1 && index != lastIndex) {
        return item.substring(lastIndex + 1, item.length);
      }
      return item;
    },
    getMathColor(item) {
      /***
       * @method  getMathColor 返回颜色
       * @param item  当前sku参数
       * @return {string} 如果有设置颜色则返回当前颜色 | 随机返回设定的颜色
       *
       */
      let index = item.indexOf("[");
      let lastIndex = item.lastIndexOf("]");
      if (index != -1 && lastIndex != -1 && index != lastIndex) {
        return item.substring(index + 1, lastIndex);
      }
      let color = [
          "#ff8917",
          "#ffd01e",
          "#e3b4b8",
          "rgb(190, 153, 255)",
          "rgb(114, 50, 221)",
          "#ee0a24",
          "#c45a65",
          "#806d9e",
          "#29b7cb",
        ],
        r = Math.round(Math.random() * 8);
      return color[r];
    },
    getFiltersName() {
      /***
       *  @method getFiltersName 因为标题和sku是连在一起的  所以要删掉SKU只保留主标题
       *  @return {string}  当前标题
       */
      let title = this.detail.goods_name;
      let sku = this.detail.goods_spec;

      for (const key in sku) {
        if (title.lastIndexOf(sku[key]) != -1) {
          return title.slice(0, title.lastIndexOf(sku[key]));
        }
      }
    },
    async getYjCount() {
      /**
       * 方法说明
       * @method getYjCount   佣金计算器
       * @param {Number} data 商品ID
       * @param {Number} price 产品价格
       * @return {Object} 返回可返多少佣金
       */

      let data = await post("/Membergoodsbrowse/getYongjin_v2", {
        data: {
          goods_id: this.detail.goods_id,
          goods_price: this.detail.promotion_price
            ? this.detail.promotion_price
            : this.$refs.price.innerHTML,
        },
      });
      let yj_msg = typeof data === "string" ? data : data.result;
      Toast("可返" + String(yj_msg) + "元。(仅供参考，以结算为准。)");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.goods_header {
  background: #fff;
  width: rem(710);
  margin: 0 auto;
  margin-top: rem(24);
  border-radius: rem(20);
  box-sizing: border-box;
  padding: rem(14) rem(12);
  .g-price {
    // align-items: flex-end;
    .g-p-sale {
      color: rgb(236, 117, 32);
      font-size: 24px;
      span {
        font-size: 14px;
      }
    }
    .g-p-origin {
      margin-left: rem(12);
      color: #a3a3a3;
      margin-top: rem(20);
      font-size: 10px;
      span {
        text-decoration: line-through;
      }
    }
  }
  .g-h-icon {
    margin-top: rem(24);
    margin-bottom: rem(10);
    span {
      flex: 1;
      text-indent: rem(30);
      text-align: center;
    }
    .g-h-i-yj {
      background: url("~@image/order/jisuan.png") no-repeat rem(70) center;
      background-size: rem(22);
    }
    .g-h-i-code {
      background: url("~@image/order/me_code.png") no-repeat rem(56) rem(0);
      background-size: rem(32);
    }
    .g-h-i-share {
      background: url("~@image/order/share.png") no-repeat rem(30) center;
      background-size: rem(30);
    }
  }
  .g-title {
    position: relative;

    .title {
      // text-indent: rem(120);
      font-size: 16px;
      font-weight: bold;
    }

    .tip {
      // position: absolute;
      display: inline-block;
      background: brown;
      margin: rem(4);
      padding: 0 6px;
      border-radius: rem(4);
      color: #fff;
      font-size: 10px;
      top: rem(8);
      border-radius: rem(6);
    }
  }
  .g-share {
    // height: 2.4rem;
    text-align: center;
    margin: 0 rem(40) 0 rem(30);
    p {
      font-size: rem(18);
    }
  }

  .g-subtitle {
    color: #a6a6a6;
  }
}
</style>
