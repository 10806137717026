var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('ul',{staticClass:"dShop_Right-ul"},[_c('div',{staticClass:"g-s-l-title flex-container"},[_c('span',{staticClass:"flex1"},[_vm._v("任选专区")]),_c('i',[_vm._v("任选"+_vm._s(_vm.detail.title))])]),_c('van-notice-bar',{attrs:{"mode":"closeable","wrapable":"","scrollable":false}},_vm._l((_vm.detail.level),function(item){return _c('p',{key:item.goods_id},[_vm._v(" 任选"+_vm._s(item.num)+"件"+_vm._s(item.goods_price)+"元 ")])}),0),_vm._l((_vm.detail.goods_info),function(item,index){return _c('li',{key:item.goods_id,staticClass:"flex-container"},[_c('img',{staticClass:"mainImg",attrs:{"src":item.goods_image},on:{"click":function($event){_vm.imgshow = true;
            _vm.zoomImgArr = item.goods_image;}}}),_c('div',{staticClass:"data"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.goods_name)+" ")]),_c('div',{staticClass:"price flex-container"},[_c('span',[_vm._v("￥"+_vm._s(_vm.setPrice(item.goods_price)))]),(
                item.marketprice != item.price &&
                  _vm.setPrice(item.goods_price).length < 6
              )?_c('i',[_vm._v("￥"+_vm._s(_vm.setPrice(item.goods_price)))]):_vm._e(),_c('div',{staticClass:"action flex-container flex1"},[[_c('img',{style:({
                    opacity: _vm.spec[index].sku_num > 0 ? '1' : '0.3',
                  }),attrs:{"src":require("@image/shop/shop_action_subtract.png")},on:{"click":function($event){return _vm.addChcek(index, 'sub')}}}),_c('b',[_vm._v(_vm._s(_vm.spec[index].sku_num))])],[_c('img',{style:({
                    opacity: _vm.check_num >= _vm.sku_max_num ? '0.3' : '1',
                  }),attrs:{"src":require("@image/shop/shop_action_add.png")},on:{"click":function($event){return _vm.addChcek(index, 'add')}}})]],2)])])])})],2)]),_c('van-popup',{style:({ width: '100%' }),attrs:{"get-container":"body","closeable":"","close-on-popstate":""},model:{value:(_vm.imgshow),callback:function ($$v) {_vm.imgshow=$$v},expression:"imgshow"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.zoomImgArr}})]),_c('div',{staticClass:"fixed_tips"},[(_vm.sumText(_vm.total_num).result == false && _vm.sumText(_vm.total_num).result2)?[_vm._v(" 任选"+_vm._s(_vm.sumText(_vm.total_num).result2.num)+"件可享"+_vm._s(_vm.sumText(_vm.total_num).result2.goods_price)+"元下单 (已选"+_vm._s(_vm.total_num)+"件) ")]:(_vm.sumText(_vm.total_num).result && _vm.sumText(_vm.total_num).result2)?[_vm._v(" 已享受任选"+_vm._s(_vm.sumText(_vm.total_num).result.num)+"件优惠，"),_c('span',{staticStyle:{"color":"#ee0a24"}},[_vm._v("差"+_vm._s(_vm.sumText(_vm.total_num).result2.num - _vm.total_num)+"件可再享"+_vm._s(_vm.sumText(_vm.total_num).result2.goods_price)+"元任购"+_vm._s(_vm.sumText(_vm.total_num).result2.num)+"件")])]:[_c('span',{staticStyle:{"color":"#ee0a24"}},[_vm._v("已享受最高优惠")])]],2),_c('van-goods-action',[_c('van-goods-action-button',{style:({ opacity: _vm.findTips(_vm.total_num) ? '1' : '.5' }),attrs:{"type":"danger","text":"立即购买"},on:{"click":_vm.onClickButton}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }