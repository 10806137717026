<template>
  <div>
    <div>
      <ul class="dShop_Right-ul">
        <div class="g-s-l-title flex-container">
          <span class="flex1">任选专区</span>
          <i>任选{{ detail.title }}</i>
        </div>

        <van-notice-bar mode="closeable" wrapable :scrollable="false">
          <p v-for="item in detail.level" :key="item.goods_id">
            任选{{ item.num }}件{{ item.goods_price }}元
          </p>
        </van-notice-bar>

        <li
          class="flex-container"
          v-for="(item, index) in detail.goods_info"
          :key="item.goods_id"
        >
          <img
            :src="item.goods_image"
            class="mainImg"
            @click="
              imgshow = true;
              zoomImgArr = item.goods_image;
            "
          />
          <div class="data">
            <div class="title">
              <!-- <img src="@image/shop/Rectangle 10.png" class="tip" /> -->
              {{ item.goods_name }}
            </div>
            <!-- <span class="subTitle">&nbsp;</span> -->
            <div class="price flex-container">
              <span>￥{{ setPrice(item.goods_price) }}</span>
              <i
                v-if="
                  item.marketprice != item.price &&
                    setPrice(item.goods_price).length < 6
                "
                >￥{{ setPrice(item.goods_price) }}</i
              >
              <div class="action flex-container flex1">
                <template>
                  <img
                    src="@image/shop/shop_action_subtract.png"
                    @click="addChcek(index, 'sub')"
                    :style="{
                      opacity: spec[index].sku_num > 0 ? '1' : '0.3',
                    }"
                  />
                  <b>{{ spec[index].sku_num }}</b>
                </template>
                <template>
                  <img
                    src="@image/shop/shop_action_add.png"
                    @click="addChcek(index, 'add')"
                    :style="{
                      opacity: check_num >= sku_max_num ? '0.3' : '1',
                    }"
                  />
                </template>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <van-popup
      v-model="imgshow"
      get-container="body"
      :style="{ width: '100%' }"
      closeable
      close-on-popstate
    >
      <img :src="zoomImgArr" style="width: 100%" />
    </van-popup>

    <div class="fixed_tips">
      <template
        v-if="sumText(total_num).result == false && sumText(total_num).result2"
      >
        任选{{ sumText(total_num).result2.num }}件可享{{
          sumText(total_num).result2.goods_price
        }}元下单 (已选{{ total_num }}件)
      </template>

      <template
        v-else-if="sumText(total_num).result && sumText(total_num).result2"
      >
        已享受任选{{ sumText(total_num).result.num }}件优惠，<span
          style="color:#ee0a24"
          >差{{ sumText(total_num).result2.num - total_num }}件可再享{{
            sumText(total_num).result2.goods_price
          }}元任购{{ sumText(total_num).result2.num }}件</span
        >
      </template>

      <template v-else>
        <span style="color:#ee0a24">已享受最高优惠</span>
      </template>
    </div>

    <van-goods-action>
      <!-- <van-goods-action-icon icon="chat-o" text="客服" @click="onClickIcon" /> -->
      <!-- <van-goods-action-icon icon="cart-o" text="购物车" @click="onClickIcon" /> -->
      <!-- <van-goods-action-icon icon="shop-o" text="店铺" @click="onClickIcon" /> -->
      <van-goods-action-button
        type="danger"
        text="立即购买"
        :style="{ opacity: findTips(total_num) ? '1' : '.5' }"
        @click="onClickButton"
      />
    </van-goods-action>
  </div>
</template>

<script>
var level2 = [];
import {
  Stepper,
  Toast,
  Popup,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  NoticeBar,
} from "vant";

export default {
  components: {
    vanNoticeBar: NoticeBar,
    vanStepper: Stepper,
    vanPopup: Popup,
    vanGoodsAction: GoodsAction,
    vanGoodsActionIcon: GoodsActionIcon,
    vanGoodsActionButton: GoodsActionButton,
  },
  props: {
    detail: {},
  },
  watch: {
    detail: {
      // deep: true,
      handler() {
        this.init();
      },
    },
  },
  data() {
    return {
      imgshow: false,
      sku_max_num: 5,
      zoomImgArr: "",
      spec: [],
      level: [],
      levelTip: "",
      check_num: 0,
      total_num: 0,
    };
  },
  created() {
    this.init();
  },
  methods: {
    onClickIcon() {
      Toast("点击图标");
    },
    onClickButton() {
      let card = "",
        total = 0;

      if (this.spec && this.spec.length != 0) {
        for (let index = 0; index < this.spec.length; index++) {
          total += Number(this.spec[index].sku_num);

          if (this.spec[index].sku_num != 0) {
            card += `${this.spec[index].goods_id}|${this.spec[index].sku_num}${
              index == this.spec.length - 1 ? "" : ","
            }`;
          }
        }

        if (this.detail.level.find((item) => item.num == total)) {
          this.$router.push({
            name: "Buy_Multiple",
            query: {
              type: 0,
              ms_id: this.detail.id,
              cart_id: card,
            },
          });
        } else {
          Toast(`只能选择【${this.levelTip}】区间数量才可以下单哦`);
        }
      } else {
        Toast("请选择产品");
      }
    },
    findTips(num) {
      if (num) {
        return this.detail.level.find((item) => item.num == num);
      }
    },
    sumText(num) {
      if (num < level2[0]?.num)
        return {
          result: false,
          result2: level2[0],
        };
      let result = level2.find((item) => item.num == num);
      let result2 = level2.find((item) => item.num > num);
      let result3 = level2.find((item) => item.num < num);
      return {
        result: result ? result : result2 ? result3 : false,
        result2: result2 ? result2 : false,
      };
    },
    sortArr(arr) {
      if (arr) {
        // level2 = arr.sort((a, b) => a - b);

        level2 = arr.sort((a, b) => {
          if (Number(a.num) > Number(b.num)) {
            return 1;
          }
          if (Number(a.num) < Number(b.num)) {
            return -1;
          }
          return 0;
        });
      }
    },
    addChcek(index, sub) {
      if (sub == "add") {
        if (level2.find((item) => item.num > this.total_num)) {
          this.spec[index].sku_num += 1;
        } else {
          Toast("已享受最高优惠，不能再添加啦~");
          return false;
        }
      } else {
        if (this.spec[index].sku_num != 0) {
          this.spec[index].sku_num -= 1;
        } else {
          Toast(`不能再少了`);
        }
      }
      let t = 0;
      for (let index = 0; index < this.spec.length; index++) {
        t += this.spec[index].sku_num;
      }
      this.total_num = t;
    },
    fChangeOptional(i) {
      // let o = JSON.parse(JSON.stringify(this.optionalSku));
      // // console.log(this.optionalSku[i].sku_num);
      // if (this.check_num >= this.sku_max_num) {
      //   // this.$set(this.optionalSku, [i], {});
      //   this.optionalSku[i] = {
      //     goods_id: o[i].goods_id,
      //     sku_num: o[i].sku_num--,
      //   };
      //   Toast(`只能任选${this.sku_max_num}件`);
      //   return false;
      // }
      // let num = 0;
      // for (let index = 0; index < o.length; index++) {
      //   num += o[index].sku_num;
      // }
      // this.check_num = num;
    },
    setPrice(val) {
      let arr = toString(val).split(".");
      if (arr[1] == "00") {
        return arr[0];
      } else {
        return val;
      }
    },
    init() {
      let optionalSku = this.detail.goods_info || [];
      if (optionalSku && optionalSku.length != 0) {
        for (let index = 0; index < optionalSku.length; index++) {
          this.spec.push({
            goods_id: optionalSku[index].goods_id,
            sku_num: 0,
          });
        }

        // if (this.detail.is_optional == 1) {
        //   this.sku_max_num = this.detail.is_optional_maxnum;
        // }

        let levelTip = "";

        for (let index = 0; index < this.detail.level.length; index++) {
          levelTip += `${this.detail.level[index].num}${
            index == this.detail.level.length - 1 ? "件" : "件，"
          }`;
        }
        this.levelTip = levelTip;
      }

      this.sortArr(this.detail.level);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
::v-deep {
  .van-notice-bar {
    padding: 16px;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .van-goods-action {
    z-index: 3;
  }
}
.dShop_Right-ul {
  width: 94%;
  background: #fff;
  margin: rem(20) auto 0;
  border-radius: rem(12);

  li {
    width: 100%;
    height: rem(200);
    align-items: center;

    .mainImg {
      width: rem(140);
      height: rem(140);
      border-radius: rem(8);
      margin: 0 rem(20);
    }
    .data {
      width: 100%;
      // @include ell;

      // display: flex;
      // flex-direction: column;

      .tip {
        display: inline-block;
        width: rem(58);
        height: rem(35);
        margin-right: rem(10);
      }
      .title {
        margin-bottom: rem(10);
        @include ell2;
        height: rem(80);
        font-size: rem(30);
        width: 93%;
        font-weight: bold;
      }
      .subTitle {
        display: block;
        font-size: rem(28);
        color: #999;
        padding-bottom: rem(11);
      }
      .price {
        span {
          display: inline-block;
          color: #fb7e1c;
          font-size: rem(34);
          padding-right: rem(6);
          // align-self: center;
        }
        i {
          text-decoration-line: line-through;
          color: #c9c9c9;
          font-size: rem(26);
          // align-self: center;
          line-height: rem(56);
        }
      }
    }
    .action {
      justify-content: flex-end;
      margin-right: rem(30);

      img {
        display: block;
        width: rem(44);
        height: rem(44);
      }
      b {
        color: #333;
        font-size: rem(30);
        margin: 0 rem(14);
      }
    }
  }
}

.g-s-l-title {
  padding: rem(30) rem(24);
  align-items: center;
  span {
    font-size: rem(36);
    font-weight: bold;
    letter-spacing: 2px;
  }
  i {
    color: #a6a6a6;
  }
}

.fixed_tips {
  position: fixed;
  bottom: 50px;
  left: 0;
  width: 100%;
  z-index: 3;
  // height: rem(50);
  background: #fffbe8;
  color: #ed6a0c;
  // line-height: rem(50);
  font-size: rem(26);
  box-sizing: border-box;
  padding: rem(8) rem(30);
  box-shadow: 0px rem(-6) rem(10) rem(-5) rgba(0, 0, 0, 0.1);
}
</style>
