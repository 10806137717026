<template>
  <div>
    <!-- 轮播图 -->
    <van-empty
      :description="errorText"
      v-if="errorText && errorText.length != 0"
      image="error"
    >
      <van-button round class="bottom-button " @click="$router.go(-1)"
        >返回</van-button
      >
      <van-button
        round
        type="primary"
        class="bottom-button"
        @click="$router.push({ name: 'HomeIndex' })"
        >回到首页</van-button
      >
    </van-empty>

    <template v-else>
      <oSwiper :imgs="goodsInfo.goods_images"></oSwiper>
      <oGoodsHeader
        :detail="goodsInfo.master_show"
        :g_id="goodsInfo.id || goodsInfo.master_show_id"
      ></oGoodsHeader>
      <goodsOptional :detail="goodsInfo"></goodsOptional>
      <ogoodsDetail :detail="goodsInfo.master_show"> </ogoodsDetail>
    </template>
  </div>
</template>

<script>
import { get } from "@get/http.js";
import oSwiper from "../goods/components/swiper.vue";
import ogoodsDetail from "../goods/components/goodsDetail.vue";
import goodsOptional from "./components/goodsOptional.vue";
import oGoodsHeader from "./components/header.vue";
import { Toast, Button, Empty } from "vant";
export default {
  components: {
    oSwiper,
    ogoodsDetail,
    goodsOptional,
    oGoodsHeader,
    vanButton: Button,
    vanEmpty: Empty,
  },
  data() {
    return {
      goodsInfo: {},
      errorText: "",
    };
  },
  async created() {
    let r = this.$route.query.goods_id;
    if (r) {
      let data = await get(
        "/v3.memberMultiSelectGoods/getDetail",
        {
          params: {
            id: r,
          },
        },
        { result: true, resultKey: "c" }
      );
      if (data.code === 10000) {
        this.goodsInfo = data.result.detail;
      } else {
        Toast("商品出错");
        this.errorText = data.message;
      }
    } else {
      this.errorText = "链接错误";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

::v-deep {
  .no-p {
    .van-skeleton {
      padding: 0;
    }
  }
  .s-swiper {
    .van-skeleton__title {
      width: 100%;
      height: rem(600);
    }
  }
}

.bottom-button {
  display: block;
  margin: 0 auto;
  margin-bottom: rem(30);
  width: rem(300);
}
</style>
